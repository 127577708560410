<template>
	<div class="px-2 py-1">
		<agent-list :hide-title="true"></agent-list>
	</div>
</template>

<script>
	export default {
		name: "IntelligentDiagnosis",
		components: {
			AgentList: () => import("@/views/front/cabr/diagnosis/agent-list/AgentList.vue"),
		},
	};
</script>

<style scoped></style>
